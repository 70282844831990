import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { connect } from "react-redux";
import { loginRequest } from "../../store/auth/actions";
import Footer from "../dashboard/Footer";
import { useNavigate, useParams } from "react-router-dom";
import { LinearProgress } from "@mui/material";

function Login(props: any) {
  const [isLoggingIn, setIsLoggingIn] = React.useState(false);
  const navigate = useNavigate();
  const { id, pwd, ip } = useParams();
  React.useEffect(() => {
    setIsLoggingIn(false);
    if (localStorage.getItem("authKey")) navigate("/dashboard");
  }, [props.isAuthenticated]);
  React.useEffect(() => {
    setIsLoggingIn(false);
  }, [props.error]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    setIsLoggingIn(true);
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let path = data.get("url")?.toString();
    path = path?.includes("http://") ? path : "http://" + path;
    props.login({
      username: data.get("username"),
      password: data.get("password"),
      path: path,
    });
  };

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <CssBaseline />
      {isLoggingIn && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: "url(https://source.unsplash.com/random)",
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Device Id"
              name="username"
              autoComplete="username"
              autoFocus
              value={"THFR1"}
            />

            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={"Password"}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="url"
              label="Address"
              type="text"
              id="url"
              autoComplete="ip"
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Footer sx={{ mt: 5 }} />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state: any) => ({
  token: state.auth.token,
  isAuthenticated: state.auth.isAuthenticated,
  error: state.auth.error,
});

const mapDispatchToProps = {
  login: loginRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
