import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Container from "@mui/material/Container";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { mainListItems } from "./listItems";
import LogoutIcon from "@mui/icons-material/Logout";
import { connect } from "react-redux";
import {
  bufferDataRequest,
  bufferUpdateDataRequest,
  getConfigRequest,
  getUsersRequest,
} from "../../store/data/actions";
import {
  useRouteError,
  useNavigate,
  Outlet,
  useParams,
} from "react-router-dom";
import Footer from "./Footer";
import IdleTimeOutHandler from "../../Components/idleSessionTimeout";
import { styled } from "@mui/material";

const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

function DashboardContent(props: any) {
  const [readableTime, setReadableTime] = React.useState<String>();
  const [timestamp, setTimestamp] = React.useState<number>(0);
  const [open, setOpen] = React.useState(true);
  const [currentPage, setCurrentPage] = React.useState("Dashboard");
  React.useEffect(() => {
    let intervalId: any = undefined;
    intervalId = setInterval(() => {
      const time = new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      }).format(timestamp + 1000);
      setReadableTime(time);
      setTimestamp(timestamp + 1000);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [timestamp]);
  React.useEffect(() => {
    if (timestamp === 0) {
      setTimestamp(props.deviceTime * 1000);
    }
  }, [props.deviceTime]);

  React.useEffect(() => {
    const uri =
      localStorage.getItem("path") +
      "/getDeviceTime?authKey=" +
      localStorage.getItem("authKey");
    fetch(uri)
      .then((resp) => {
        return resp.json();
      })
      .then((respJson) => {
        setTimestamp(respJson.deviceTime * 1000);
      });
  }, []);
  const handleToggleDrawer = () => {
    setOpen(!open);
  };
  const logout = () => {
    localStorage.clear();
    window.location.reload();
  };
  React.useEffect(() => {
    const intervalId = setInterval(() => {
      if (props.authKey) {
        props.refreshData({
          authKey: props.authKey,
          path: props.path,
        });
      }
    }, 1000);
    let tmp = window.location.pathname.slice(
      window.location.pathname.lastIndexOf("/"),
      window.location.pathname.length
    );
    switch (tmp.toUpperCase()) {
      case "/DASHBOARD":
        setCurrentPage("Dashboard");
        break;
      case "/USERS":
        setCurrentPage("Users");
        break;
      case "/SETTINGS":
        setCurrentPage("Settings");
        break;
      case "/FILESYSTEM":
        setCurrentPage("Files");
        break;
    }
    // return clearInterval(intervalId);
  }, []);

  return (
    <>
      <IdleTimeOutHandler
        timeOutInterval={600000}
        onLogout={() => {
          logout();
        }}
        onActive={() => {}}
      />

      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleToggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              {currentPage}
            </Typography>
            <Typography
              variant="body2"
              sx={{ marginRight: 2, fontWeight: "bold" }}
            >
              Device Time: {readableTime}
            </Typography>

            <IconButton
              color="inherit"
              onClick={() => {
                logout();
              }}
            >
              <Badge color="secondary">
                <LogoutIcon />
              </Badge>
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <IconButton onClick={handleToggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">{mainListItems}</List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Outlet />
            <Footer sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
    </>
  );
}

function Dashboard(props: any) {
  const navigate = useNavigate();
  React.useEffect(() => {
    if (!props.token) {
      navigate("/");
    }
  }, [localStorage.getItem("authKey")]);
  React.useEffect(() => {
    props.getConfig({ authKey: props.token, path: props.path });
    props.getUsers({ authKey: props.token, path: props.path });
  }, []);

  const dashboardContent = React.useMemo(
    () =>
      props.token && (
        <DashboardContent
          getUsers={props.getUsers}
          config={props.config}
          getConfig={props.getConfig}
          updateData={props.updateData}
          authKey={props.token}
          path={props.path}
          data={props.data}
          refreshData={props.refreshData}
          deviceTime={props.deviceTime}
        />
      ),
    [props]
  );
  return dashboardContent;
}
const mapStateToProps = (state: any) => ({
  token: localStorage.getItem("authKey"),
  path: localStorage.getItem("path"),
  isAuthenticated: state.auth.isAuthenticated,
  data: state.data.data,
  config: state.data.config,
  deviceTime: state.auth.deviceTime,
});
const mapDispatchToProps = {
  refreshData: bufferDataRequest,
  updateData: bufferUpdateDataRequest,
  getConfig: getConfigRequest,
  getUsers: getUsersRequest,
};
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
