import { all, call, put, takeLatest } from "redux-saga/effects";
import { ISettings } from "../../types/settings";

import {
  bufferDataFailure,
  bufferDataSuccess,
  bufferUpdateDataFailure,
  bufferUpdateDataSuccess,
  getConfigFailure,
  getConfigSuccess,
  getFilesFailure,
  getFilesSuccess,
  getUsersFailure,
  getUsersSuccess,
  saveUsersFailure,
  saveUsersSuccess,
  setConfigFailure,
  setConfigSuccess,
} from "./actions";
import { BUFFER_DATA_REQUEST, BUFFER_UPDATE_DATA, GET_CONFIG, GET_FILES, GET_USERS, SAVE_USERS, SET_CONFIG } from "./actionTypes";
import { BufferDataFailurePayload, BufferDataSuccessPayload, GetFileContentPayload, GetFilesPayload, GetFilesRequest } from "./types";

const bufferData = async (payload: { authKey: string; path:string;}) => {
  const  response:any = await fetch(payload.path+'/getCsv?authKey='+payload.authKey,{
      method: 'GET',
  })
  const data:BufferDataSuccessPayload|BufferDataFailurePayload=await response.json()
  return data;
};

const bufferUpdateData = async (payload: { authKey: string; csvData:string; path: string}) => {
  const  response:any = await fetch(payload.path+'/saveCsv',
  {
    method:'POST',
    body : JSON.stringify({
        authKey:payload.authKey,
        csv:payload.csvData
    })
  })
  const data:string|null=await response.text()
  return data;
};

const getConfig = async (payload: { authKey: string; path: string}) => {
  const  response:any = await fetch(payload.path+'/data?authKey='+payload.authKey)
  const data:string|null=await response.json()
  return data;
};

const setConfig = async (payload: { authKey: string; path: string,config:ISettings}) => {
  const  response:any = await fetch(payload.path+'/save',
  {
    method:'POST',
    body : JSON.stringify({
        authKey:payload.authKey,
        updatedConfig:payload.config,
    })
  })
  const data:ISettings=await response.json()
  return data;
};


const getUsers = async (payload: { authKey: string; path: string}) => {
  const  response:any = await fetch(payload.path+'/getUsers?authKey='+payload.authKey,{
    method: 'GET',
  })
  const data:string|null=await response.json()
  return data;
};

const getFiles = async (payload: GetFilesPayload) => {
  const  response:any = await fetch(localStorage.getItem("path")+'/getFiles?authKey='+payload.authKey,{
    method: 'GET',
  })
  const data:string|null=await response.json()
  return data;
};

const getFileContent = async (payload: GetFileContentPayload) => {
  const  response:any = await fetch(localStorage.getItem('path')+'/getFileContent',
  {
    method:'POST',
    body : JSON.stringify({
        authKey:payload.authKey,
        filename:payload.filename
    })
  })
  const data:string|null=await response.text()
  return data;
};


const saveUsers = async (payload: { authKey: string; user:string; path: string; }) => {
  const  response:any = await fetch(payload.path+'/saveUsers',
  {
    method:'POST',
    body : JSON.stringify({
        authKey:payload.authKey,
        user:payload.user,
    })
  })
  const data:string|null=await response.json()
  console.log(data)
  return data;
};

function* bufferDataSaga(action: any) {
  try {
    const response: string | null = yield call(bufferData, {
      authKey: action.payload.authKey,
      path:action.payload.path
    });

    yield put(
      bufferDataSuccess({
        data: response,
      })
    );
    // action.payload.callback(response.token);
  } catch (e: any) {
    yield put(
      bufferDataFailure({
        error: e.message,
      })
    );
  }
}

function* bufferUpdateDataSaga(action: any) {
  try {
    const response: string | null = yield call(bufferUpdateData, {
      authKey: action.payload.authKey,
      csvData:action.payload.csv,
      path: action.payload.path
    });

    yield put(
      bufferUpdateDataSuccess({
        data: response,
      })
    );
    // action.payload.callback(response.token);
  } catch (e: any) {
    yield put(
      bufferUpdateDataFailure({
        error: e.message,
      })
    );
  }
}

function* getConfigSaga(action: any) {
  try {
    const response: ISettings = yield call(getConfig, {
      authKey: action.payload.authKey,
      path: action.payload.path
    });

    yield put(
      getConfigSuccess({
        config: response,
      })
    );
    // action.payload.callback(response.token);
  } catch (e: any) {
    yield put(
      getConfigFailure({
        error: e.message,
      })
    );
  }
}

function* setConfigSaga(action: any) {
  try {
    const response: ISettings = yield call(setConfig, {
      authKey: action.payload.authKey,
      path: action.payload.path,
      config:action.payload.config
    });

    yield put(
      setConfigSuccess({
        config: response,
      })
    );
    // action.payload.callback(response.token);
  } catch (e: any) {
    yield put(
      setConfigFailure({
        error: e.message,
      })
    );
  }
}

function* getUsersSaga(action: any) {
  try {
    const response: string|null = yield call(getUsers, {
      authKey: action.payload.authKey,
      path: action.payload.path
    });

    yield put(
      getUsersSuccess({
        userData: response
      })
    );
  } catch (e: any) {
    yield put(
      getUsersFailure({
        error: e.message,
      })
    );
    console.error(e)
  }
}

function* getFilesSaga(action: GetFilesRequest) {
  try {
    const response: Array<Map<any,any>> = yield call(getFiles, {
      authKey: action.payload.authKey,
    });

    yield put(
      getFilesSuccess({
        files: response
      })
    );
    // action.payload.callback(response.token);
  } catch (e: any) {
    yield put(
      getFilesFailure({
        error: e.message,
      })
    );
    console.error(e)
  }
}

function* saveUsersSaga(action: any) {
  try {
    const response: string|null = yield call(saveUsers, {
      authKey: action.payload.authKey,
      path: action.payload.path,
      user:action.payload.user,
    });
    yield put(
      saveUsersSuccess({
        userData: response
      })
    );
  } catch (e: any) {
    yield put(
      saveUsersFailure({
        error: e.message,
      })
    );
  }
}

function* authSaga() {
  yield all([
    takeLatest(BUFFER_UPDATE_DATA, bufferUpdateDataSaga),
    takeLatest(BUFFER_DATA_REQUEST, bufferDataSaga),
    takeLatest(GET_CONFIG, getConfigSaga),
    takeLatest(SET_CONFIG, setConfigSaga),
    takeLatest(GET_USERS, getUsersSaga),
    takeLatest(SAVE_USERS, saveUsersSaga),
    takeLatest(GET_FILES, getFilesSaga)
  ]);
}

export default authSaga;