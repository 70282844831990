import InfoCard from "./infoCard";
import { connect } from "react-redux";
import BasicEditingGrid from "../../Components/table";
import {
  bufferUpdateDataRequest,
  saveUsersRequest,
} from "../../store/data/actions";

import React, { useEffect, useState } from "react";
import { Grid, Paper, Theme } from "@mui/material";
import {
  GridColumns,
  GridPreProcessEditCellProps,
  GridValidRowModel,
} from "@mui/x-data-grid";
import { User } from "../../store/data/types";

function MainBody(props: any) {
  const [rows, setRows] = React.useState(Array<GridValidRowModel>);
  const [users, setUsers] = useState<Array<User>>([]);
  const columns: GridColumns = [
    {
      field: "id",
      headerName: "Sr.no",
      type: "number",
      width: 60,
      editable: false,
    },
    {
      field: "timestamp",
      headerName: "Timestamp",
      type: "date",
      width: 220,
      editable: false,
    },
    {
      field: "tagId",
      headerName: "Tag ID",
      type: "string",
      width: 180,
      editable: false,
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        const hasError = params.props.value.length != 8;
        return { ...params.props, error: hasError };
      },
    },
    {
      field: "userId",
      headerName: "User Id",
      type: "string",
      width: 180,
      editable: false,
    },
    {
      field: "userName",
      headerName: "User Name",
      type: "string",
      width: 180,
      editable: false,
    },
  ];

  useEffect(() => {
    try {
      if (props.users) {
        if (!Array.isArray(props.users)) {
          let users: any[] = JSON.parse(props.users);
          setUsers(users);
        }
      }
    } catch (e) {
      setUsers([]);
    }
  }, [props.users]);

  React.useEffect(() => {
    try {
      if (props.data !== null && props.users !== null) {
        const csvData = props.data;
        csvData.reverse();
        var tempRows: any[] = [];
        csvData.forEach((element: any, index: number) => {
          var row: any = {};
          row.id = index + 1;
          const user: User[] = props.users.filter((user: any) => {
            return user.tagId === element[1];
          });
          row.timestamp = new Intl.DateTimeFormat("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          }).format(element[0] * 1000);
          if (user.length > 0) {
            row.userId = user[0].userId;
            row.userName = user[0].name;
          }
          row.tagId = element[1];
          row.tagId && tempRows.push(row);
        });
        setRows(tempRows);
      }
    } catch (e) {}
  }, [props.data, props.users]);
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={4} lg={2}>
        {props.config.DeviceConfig && (
          <InfoCard
            heading={"Total Space"}
            value={Math.round(props.config.DeviceConfig.totalSpace / 1000)}
            unit={"KB"}
          />
        )}
      </Grid>
      <Grid item xs={12} md={4} lg={2}>
        {props.config.DeviceConfig ? (
          <InfoCard
            heading={"Used Space"}
            value={Math.round(props.config.DeviceConfig.usedSpace / 1000)}
            unit={"KB"}
          />
        ) : null}
      </Grid>
      <Grid item xs={12} md={4} lg={2}>
        {props.users && (
          <InfoCard heading={"Percentage Utilised"} value={2} unit={"%"} />
        )}
      </Grid>
      <Grid item xs={12} md={4} lg={2}>
        {props.data ? (
          <InfoCard
            heading={"Total Records"}
            value={props.data.length}
            unit={"Records"}
          />
        ) : (
          <InfoCard
            heading={"Total Records"}
            value={0}
            unit={"Records"}
            secondaryText={"You have these many records in the buffer."}
          />
        )}
      </Grid>
      <Grid item xs={12} md={4} lg={2}>
        {props.users && (
          <InfoCard
            heading={"Total Users"}
            value={users.length}
            unit={"Users"}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <BasicEditingGrid
            deleteRow={(index: number[]) => {
              const result = rows.filter((row) => !index.includes(row.id));
              setRows(result);
              let csv: any[] = [];
              result.forEach((element) => {
                const times = Math.floor(
                  new Date(element.timestamp).getTime() / 1000
                );
                // const row = times + ',' + element.tagId + '\r\n'
                let row: any[] = [];
                row.push(times);
                row.push(element.tagId);
                csv.push(row);
              });
              props.updateData({
                authKey: props.authKey,
                csv: csv,
                path: props.path,
              });
            }}
            rows={rows}
            columns={columns}
          />
        </Paper>
      </Grid>
      <Grid item xs={4}>
        <Paper></Paper>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state: any) => ({
  authKey: localStorage.getItem("authKey"),
  path: localStorage.getItem("path"),
  users: state.data.userData,
  config: state.data.config,
  data: state.data.data,
});

const mapDispatchToProps = {
  saveUser: saveUsersRequest,
  updateData: bufferUpdateDataRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(MainBody);
