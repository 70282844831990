import {
    BUFFER_DATA_REQUEST,
    BUFFER_DATA_SUCCESS,
    BUFFER_DATA_FAILURE,
    BUFFER_UPDATE_DATA,
    BUFFER_UPDATE_DATA_SUCCESS,
    BUFFER_UPDATE_DATA_FAILURE,
    GET_CONFIG,
    GET_CONFIG_SUCCESS,
    GET_CONFIG_FAILURE,
    GET_USERS,
    GET_USERS_FAILURE,
    GET_USERS_SUCCESS,
    SAVE_USERS,
    SAVE_USERS_SUCCESS,
    SAVE_USERS_FAILURE,
    SET_CONFIG_SUCCESS,
    SET_CONFIG_FAILURE,
    SET_CONFIG,
    GET_FILES,
    GET_FILES_SUCCESS,
    GET_FILES_FAILURE,
    GET_FILE_CONTENT,
    GET_FILE_CONTENT_SUCCESS,
    GET_FILE_CONTENT_FAILURE
  } from "./actionTypes";
  import {
    BufferDataPayload,
    BufferDataRequest,
    BufferDataSuccess,
    BufferDataSuccessPayload,
    BufferDataFailure,
    BufferDataFailurePayload,
    BufferUpdateDataPayload,
    BufferUpdateDataSuccessPayload,
    BufferUpdateDataFailurePayload,
    BufferUpdateDataRequest,
    BufferUpdateDataSuccess,
    BufferUpdateDataFailure,
    GetConfigRequest,
    GetConfigPayload,
    GetConfigSuccessPayload,
    GetConfigSuccess,
    GetConfigFailurePayload,
    GetConfigFailure,
    GetUserFailure,
    GetUserSuccess,
    GetUserRequest,
    GetUserPayload,
    GetUserSuccessPayload,
    GetUserFailurePayload,
    SaveUserFailurePayload,
    SaveUserFailure,
    SaveUserSuccess,
    SaveUserSuccessPayload,
    SaveUserPayload,
    SaveUserRequest,
    SetConfigPayload,
    SetConfigRequest,
    SetConfigSuccessPayload,
    SetConfigSuccess,
    SetConfigFailurePayload,
    SetConfigFailure,
    GetFilesPayload,
    GetFilesRequest,
    GetFilesSuccess,
    GetFilesFailure,
    GetFilesSuccessPayload,
    GetFilesFailurePayload,
    GetFileContentPayload,
    GetFileContentRequest,
    GetFileContentSuccessPayload,
    GetFileContentFailurePayload,
    GetFileContentSuccess,
    GetFileContentFailure,
  } from "./types";
  
  export const bufferDataRequest = (payload: BufferDataPayload): BufferDataRequest => ({
    type: BUFFER_DATA_REQUEST,
    payload,
  });
  
  export const bufferDataSuccess = (payload: BufferDataSuccessPayload): BufferDataSuccess => ({
    type: BUFFER_DATA_SUCCESS,
    payload,
  });
  
  export const bufferDataFailure = (payload: BufferDataFailurePayload): BufferDataFailure => ({
    type: BUFFER_DATA_FAILURE,
    payload,
  });

  export const bufferUpdateDataRequest = (payload: BufferUpdateDataPayload): BufferUpdateDataRequest => ({
    type: BUFFER_UPDATE_DATA,
    payload,
  });
  
  export const bufferUpdateDataSuccess = (payload: BufferUpdateDataSuccessPayload): BufferUpdateDataSuccess => ({
    type: BUFFER_UPDATE_DATA_SUCCESS,
    payload,
  });

  export const bufferUpdateDataFailure = (payload: BufferUpdateDataFailurePayload): BufferUpdateDataFailure => ({
    type: BUFFER_UPDATE_DATA_FAILURE,
    payload,
  });

  export const setConfigRequest = (payload: SetConfigPayload): SetConfigRequest => ({
    type: SET_CONFIG,
    payload,
  });
  
  export const setConfigSuccess = (payload: SetConfigSuccessPayload): SetConfigSuccess => ({
    type: SET_CONFIG_SUCCESS,
    payload,
  });

  export const setConfigFailure = (payload: SetConfigFailurePayload): SetConfigFailure => ({
    type: SET_CONFIG_FAILURE,
    payload,
  });

  export const getConfigRequest = (payload: GetConfigPayload): GetConfigRequest => ({
    type: GET_CONFIG,
    payload,
  });
  
  export const getConfigSuccess = (payload: GetConfigSuccessPayload): GetConfigSuccess => ({
    type: GET_CONFIG_SUCCESS,
    payload,
  });

  export const getConfigFailure = (payload: GetConfigFailurePayload): GetConfigFailure => ({
    type: GET_CONFIG_FAILURE,
    payload,
  });

  export const getUsersRequest = (payload: GetUserPayload): GetUserRequest => ({
    type: GET_USERS,
    payload,
  });
  
  export const getUsersSuccess = (payload: GetUserSuccessPayload): GetUserSuccess => ({
    type: GET_USERS_SUCCESS,
    payload,
  });

  export const getUsersFailure = (payload: GetUserFailurePayload): GetUserFailure => ({
    type: GET_USERS_FAILURE,
    payload,
  });
  
  export const saveUsersRequest = (payload: SaveUserPayload): SaveUserRequest => ({
    type: SAVE_USERS,
    payload,
  });
  
  export const saveUsersSuccess = (payload: SaveUserSuccessPayload): SaveUserSuccess => ({
    type: SAVE_USERS_SUCCESS,
    payload,
  });

  export const saveUsersFailure = (payload: SaveUserFailurePayload): SaveUserFailure => ({
    type: SAVE_USERS_FAILURE,
    payload,
  });

  export const getFilesRequest = (payload: GetFilesPayload): GetFilesRequest => ({
    type: GET_FILES,
    payload,
  });
  
  export const getFilesSuccess = (payload: GetFilesSuccessPayload): GetFilesSuccess => ({
    type: GET_FILES_SUCCESS,
    payload,
  });

  export const getFilesFailure = (payload: GetFilesFailurePayload): GetFilesFailure => ({
    type: GET_FILES_FAILURE,
    payload,
  });
  export const getFileContentRequest = (payload: GetFileContentPayload): GetFileContentRequest => ({
    type: GET_FILE_CONTENT,
    payload,
  });
  
  export const getFileContentSuccess = (payload: GetFileContentSuccessPayload): GetFileContentSuccess => ({
    type: GET_FILE_CONTENT_SUCCESS,
    payload,
  });

  export const getFileContentFailure = (payload: GetFileContentFailurePayload): GetFileContentFailure => ({
    type: GET_FILE_CONTENT_FAILURE,
    payload,
  });
