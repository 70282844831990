import * as React from 'react';
import {
  DataGrid,
  GridRowModel,
  GridRowId,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  GridToolbarColumnsButton,
  GridToolbarQuickFilter,
  GridSelectionModel,
} from '@mui/x-data-grid';
import Snackbar from '@mui/material/Snackbar';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Alert, { AlertProps } from '@mui/material/Alert';
import DeleteIcon from '@mui/icons-material/Delete';

interface User {
  name: string;
  age: number;
  id: GridRowId;
  dateCreated: Date;
  lastLogin: Date;
}
const useFakeMutation = () => {
  return React.useCallback(
    (user: Partial<User>) =>
      new Promise<Partial<User>>((resolve, reject) =>
        setTimeout(() => {
          if (user.name?.trim() === '') {
            reject();
          } else {
            resolve(user);
          }
        }, 200),
      ),
    [],
  );
};

function computeMutation(newRow: GridRowModel, oldRow: GridRowModel) {

  for(const element in newRow){
    if(newRow[element]!==oldRow[element]){
      return element+` from '${oldRow[element]}' to '${newRow[element]}'`;
    }
  }
  return null;
}

function Table(props:any) {
  const [isDeletBtnDisabled,setIsDeletBtnDisabled]=React.useState(true)
  const [selectionModel, setSelectionModel] = React.useState<GridSelectionModel>([]);

  const mutateRow = useFakeMutation();
  const noButtonRef = React.useRef<HTMLButtonElement>(null);
  const [promiseArguments, setPromiseArguments] = React.useState<any>(null);

  const [snackbar, setSnackbar] = React.useState<Pick<
    AlertProps,
    'children' | 'severity'
  > | null>(null);

  const handleCloseSnackbar = () => setSnackbar(null);

  const processRowUpdate = React.useCallback(
    (newRow: GridRowModel, oldRow: GridRowModel) =>
      new Promise<GridRowModel>((resolve, reject) => {
        const mutation = computeMutation(newRow, oldRow);
        if (mutation) {
          // Save the arguments to resolve or reject the promise later
          setPromiseArguments({ resolve, reject, newRow, oldRow });
        } else {
          resolve(oldRow); // Nothing was changed
        }
      }),
    [],
  );

  const handleNo = () => {
    const { oldRow, resolve } = promiseArguments;
    resolve(oldRow); // Resolve with the old row to not update the internal state
    setPromiseArguments(null);
  };

  const handleYes = async () => {
    const { newRow, oldRow, reject, resolve } = promiseArguments;

    try {
      // Make the HTTP request to save in the backend
      const response = await mutateRow(newRow);
      setSnackbar({ children: 'Updated successfully.', severity: 'success' });
      
      var tempRows:any[]=[]
      props.rows.forEach((e:any,i:number)=>{
        if(e.userId===newRow.userId){
          tempRows.push(newRow)
        }
        else{
          tempRows.push(e)
        }
      })
      setPromiseArguments(null);
      props.updatedRows(tempRows)
      resolve(response);
    } 
    catch (error) {
      alert(error)
      setSnackbar({ children: "Value can't be empty", severity: 'error' });
      reject(oldRow);
      setPromiseArguments(null);
    }
  };

  const handleEntered = () => {
    // The `autoFocus` is not used because, if used, the same Enter that saves
    // the cell triggers "No". Instead, we manually focus the "No" button once
    // the dialog is fully open.
    // noButtonRef.current?.focus();
  };

  const renderConfirmDialog = () => {
    if (!promiseArguments) {
      return null;
    }
    const { newRow, oldRow } = promiseArguments;
    const mutation = computeMutation(newRow, oldRow);

    return (
      <Dialog
        maxWidth="xs"
        TransitionProps={{ onEntered: handleEntered }}
        open={!!promiseArguments}
      >
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent dividers>
          {`Pressing 'Yes' will change ${mutation}.`}
        </DialogContent>
        <DialogActions>
          <Button ref={noButtonRef} onClick={handleNo}>
            No
          </Button>
          <Button onClick={handleYes}>Yes</Button>
        </DialogActions>
      </Dialog>
    );
  };
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarQuickFilter/>
        <GridToolbarFilterButton />
        <GridToolbarExport />
        <GridToolbarDensitySelector/>
        <GridToolbarColumnsButton/>
        <Button variant="text" startIcon={<DeleteIcon />} onClick={()=>{
          props.deleteRow(selectionModel)
        }} 
        disabled={isDeletBtnDisabled}>DELETE ROW</Button>
      </GridToolbarContainer>
    );
  }

  return (
    <div style={{ height: 600, width: '100%',padding:'30px' }}>
      {renderConfirmDialog()}
      <DataGrid
        rows={props.rows}
        columns={props.columns}
        processRowUpdate={processRowUpdate}
        experimentalFeatures={{ newEditingApi: true }}
        components={{ Toolbar: CustomToolbar }}
        checkboxSelection={true}
        onSelectionModelChange={(newSelectionModel) => {
          if(newSelectionModel.length>0){
            setIsDeletBtnDisabled(false)
            setSelectionModel(newSelectionModel);
          }else{
            setIsDeletBtnDisabled(true)
            setSelectionModel(newSelectionModel);
          }
      }}
        selectionModel={selectionModel}
      />
      {!!snackbar && (
        <Snackbar open onClose={handleCloseSnackbar} autoHideDuration={6000}>
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </div>
  );
}

export default Table