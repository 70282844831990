import {
  Alert,
  AlertProps,
  Box,
  Button,
  ButtonGroup,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import { connect } from "react-redux";
import Table from "../../Components/table";
import {
  GridColumns,
  GridPreProcessEditCellProps,
  GridValidRowModel,
} from "@mui/x-data-grid";
import { getUsersRequest, saveUsersRequest } from "../../store/data/actions";
import { useEffect } from "react";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs, { Dayjs } from "dayjs";
import { User } from "../../store/data/types";

const columns: GridColumns = [
  { field: "id", headerName: "Sr. no.", type: "number", width: 60 },
  {
    field: "userId",
    headerName: "User ID",
    type: "string",
    width: 140,
    editable: true,
    // preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
    // const hasError = params.props.value.length != 8;
    // return { ...params.props, error: hasError };
    // }
  },
  {
    field: "tagId",
    headerName: "Tag ID",
    type: "string",
    width: 140,
    editable: true,
  },
  {
    field: "name",
    headerName: "User Name",
    type: "string",
    width: 140,
    editable: true,
  },
  {
    field: "contactInfo",
    headerName: "User Contact",
    type: "string",
    width: 130,
    editable: true,
  },
  {
    field: "inTime",
    headerName: "Entry Time",
    type: "string",
    width: 100,
    editable: true,
  },
  {
    field: "outTime",
    headerName: "Exit Time",
    type: "string",
    width: 100,
    editable: true,
  },
];

export const UsersPage = (props: any) => {
  const [rows, setRows] = useState(Array<GridValidRowModel>);
  const [tagValue, setTagValue] = useState("");
  const [startTime, setStartTime] = useState<Dayjs | null>(dayjs("hh:mm A"));
  const [endTime, setEndTime] = useState<Dayjs | null>(dayjs("hh:mm A"));
  const [snackbar, setSnackbar] = React.useState<Pick<
    AlertProps,
    "children" | "severity"
  > | null>(null);

  const table = useMemo(() => {
    return (
      <Table
        updatedRows={(rows: any) => {
          let users: any[] = [];
          rows.forEach((element: any) => {
            var user: any[] = [];
            user.push(element.name);
            user.push(element.userId);
            user.push(element.tagId);
            user.push(element.contact);
            user.push(element.entryTime);
            user.push(element.exitTime);
            users.push(user);
          });
          props.saveUser({
            user: JSON.stringify(users),
            authKey: props.authKey,
            path: props.path,
          });
        }}
        deleteRow={(index: number[]) => {
          const result = rows.filter((row) => !index.includes(row.id));
          let users: any[] = [];
          result.forEach((element) => {
            var user: any[] = [];
            user.push(element.name);
            user.push(element.userId);
            user.push(element.tagId);
            user.push(element.contact);
            user.push(element.entryTime);
            user.push(element.exitTime);
            users.push(user);
          });
          props.saveUser({
            user: JSON.stringify(users),
            authKey: props.authKey,
            path: props.path,
          });
          setSnackbar({ children: "Row Deleted", severity: "success" });
        }}
        rows={rows}
        columns={columns}
      />
    );
  }, [rows, columns]);

  const handleCloseSnackbar = () => setSnackbar(null);

  useEffect(() => {
    if (props.users) {
      try {
        let users: User[] = props.users ? props.users : [];
        setRows(users);
      } catch (e) {
        console.error("Can't list users: ", e);
      }
    }
  }, [props.users]);

  const getLastScannedId = () => {
    fetch(props.path + "/getLastScannedTag?authKey=" + props.authKey)
      .then((response) => response.text())
      .then((response) => {
        console.log(response);
        if (response == "") {
          setSnackbar({ children: "No Tag is Scanned", severity: "error" });
        } else {
          setTagValue(response);
        }
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const updateUsers = (user: any[], multiple: boolean = true): boolean => {
    let users = props.users;
    var check = users.filter((element: User) => {
      return element.tagId === user[2] || element.userId === user[1];
    });

    let tmpUsers: any[] = [];
    // users.forEach((element: User) => {
    //   tmpUsers.push([
    //     element.name,
    //     element.userId,
    //     element.tagId,
    //     element.contactInfo,
    //     element.inTime,
    //     element.outTime,
    //   ]);
    // });
    if (check.length == 0) {
      if (multiple) {
        tmpUsers.push(...user);
      } else {
        tmpUsers.push(user);
      }
      tmpUsers.forEach((element) => {
        console.log(JSON.stringify(element));
        props.saveUser({
          user: JSON.stringify(element),
          authKey: props.authKey,
          path: props.path,
        });
      });
      return true;
    } else {
      check.forEach((element: any, index: number) => {
        setSnackbar({
          children:
            "User Id " +
            element[2] +
            " or tag Id " +
            element[1] +
            " already exists at row " +
            (index + 1) +
            " Try modifying or delete record first.",
          severity: "error",
        });
      });
      return false;
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let user: any[] = [];
    user.push(data.get("username"));
    user.push(data.get("userId"));
    user.push(data.get("tagId"));
    user.push(data.get("contactInfo"));
    user.push(startTime);
    user.push(endTime);
    console.log(user);
    if (updateUsers(user, false)) {
      getLastScannedId();
      props.getUsers({ authKey: props.authKey });
      event.currentTarget.reset();
    }
  };
  function downloadTemplate() {
    const rows = [
      ["Name", "User Id", "Tag Id", "Contact", "Entry Time", "Exit Time"],
    ];

    let csvContent =
      "data:text/csv;charset=utf-8," + rows.map((e) => e.join(",")).join("\n");
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", new Date() + "users.csv");
    document.body.appendChild(link); // Required for FF

    link.click();
  }
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <Grid container direction={"column"} spacing={3}>
            <Grid item xs={12}>
              <Paper style={{ padding: 30 }}>
                <Typography
                  sx={{ fontSize: 15 }}
                  align="center"
                  color="text.primary"
                  gutterBottom
                >
                  {"User Registration"}
                </Typography>
                <Box component="form" onSubmit={handleSubmit}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="userId"
                    label="User ID"
                    name="userId"
                    autoComplete="userId"
                    autoFocus
                  />
                  <TextField
                    sx={{ mt: 1 }}
                    margin="normal"
                    required
                    fullWidth
                    name="username"
                    label="User Name"
                    id="username"
                    autoComplete="name"
                  />
                  <FormControl sx={{ mt: 1 }} fullWidth variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">
                      Tag ID
                    </InputLabel>
                    <OutlinedInput
                      id="tagId"
                      name="tagId"
                      value={tagValue}
                      onChange={(e) => {
                        setTagValue(e.target.value);
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <Tooltip title="Get last scanned tag ID">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => getLastScannedId()}
                              // onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              <DocumentScannerIcon />
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      }
                      label="Tag ID"
                    />
                  </FormControl>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="contactInfo"
                    label="Contact"
                    type="contact"
                    id="contactInfo"
                    autoComplete="contact"
                  />
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      className="startTime"
                      label="Entry time"
                      sx={{ mt: 1, mb: 1, width: "100%" }}
                      format={"hh:mm A"}
                      value={startTime}
                      defaultValue={dayjs()}
                      onChange={(value: any) =>
                        setStartTime(value.format("hh:mm A"))
                      }
                    />
                    <TimePicker
                      className="endTime"
                      label="Exit time"
                      sx={{ mt: 1, width: "100%" }}
                      format={"hh:mm A"}
                      value={endTime}
                      defaultValue={dayjs(dayjs().format("hh:mm A"))}
                      onChange={(value: any) =>
                        setEndTime(value.format("hh:mm A"))
                      }
                    />
                  </LocalizationProvider>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    onClick={(e) => {}}
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Add User
                  </Button>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper style={{ padding: 30, paddingTop: 20 }}>
                <Typography
                  sx={{ fontSize: 15 }}
                  align="center"
                  color="text.primary"
                  gutterBottom
                >
                  {"User Registration"}
                </Typography>
                <Button
                  style={{ margin: 5 }}
                  fullWidth
                  variant="outlined"
                  onClick={() => {
                    downloadTemplate();
                  }}
                >
                  Download Template
                </Button>
                <Button
                  fullWidth
                  style={{ margin: 5 }}
                  variant="outlined"
                  component="label"
                  onChange={(e: any) => {
                    var file = e.target.files[0];
                    if (!file) {
                      return;
                    }
                    if (file.name.split(".")[1].toUpperCase() !== "CSV") {
                      setSnackbar({
                        children: "Please upload CSV file only.",
                        severity: "error",
                      });
                      return;
                    }
                    var reader = new FileReader();
                    reader.addEventListener("load", function (e) {
                      if (e.target) {
                        let csvdata = e.target.result?.toString();
                        let csvRows = csvdata?.split("\r\n");
                        csvRows?.shift();
                        let users: any[] = [];
                        csvRows?.forEach((element, index) => {
                          let values = element.split(",");
                          const user = [...values];
                          users.push(user);
                        });
                        updateUsers(users);
                      }
                    });
                    reader.readAsBinaryString(file);
                  }}
                >
                  Upload File
                  <input type="file" hidden />
                </Button>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={8}>
          <Paper>{table}</Paper>
        </Grid>
      </Grid>
      {!!snackbar && (
        <Snackbar open onClose={handleCloseSnackbar} autoHideDuration={6000}>
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  authKey: localStorage.getItem("authKey"),
  path: localStorage.getItem("path"),
  users: state.data.userData,
  data: state.data.csvData,
});

const mapDispatchToProps = {
  saveUser: saveUsersRequest,
  getUsers: getUsersRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersPage);
