import { PlaylistAddOutlined } from "@mui/icons-material";
import axios from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";

import {
  loginFailure,
  loginSuccess,
} from "./actions";
import { LOGIN_REQUEST } from "./actionTypes";

const login = async (payload: { username: string; password: string; path:string; }) => {
  const  response = await fetch(payload.path+'/auth', 
  {  
      method: 'POST',
      body: JSON.stringify({ username: payload.username, password: payload.password })
  })
  const data=await response.json()
  return data;
};

function* loginSaga(action: any) {
  try {
    const response: { authKey: string, deviceTime:number } = yield call(login, {
      username: action.payload.username,
      password: action.payload.password,
      path: action.payload.path
    });

    yield put(
      loginSuccess({
        token: response.authKey,
        isAuthenticated:true,
        path:action.payload.path,
        deviceTime:response.deviceTime
      })
    );
    localStorage.setItem('authKey',response.authKey)
    localStorage.setItem('path',action.payload.path)
  } catch (e: any) {
    yield put(
      loginFailure({
        error: e.message,
        isAuthenticated:false
      })
    );
  }
}


function* authSaga() {
  yield all([takeLatest(LOGIN_REQUEST, loginSaga)]);
}

export default authSaga;