import React, { useEffect, useState} from 'react';
import moment from 'moment'

const IdleTimeOutHandler = (props:any)=>{
    const[isLogout,setLogout]=useState(false)
    let timer:any=undefined;
    const events= ['click','load','keydown']
    const eventHandler =(eventType:Event)=>{
        if(!isLogout){
            localStorage.setItem('lastInteractionTime',moment().toString())
            if(timer){
                props.onActive();
                startTimer();
            }
        }
        
    };
    
    useEffect(()=>{
        addEvents();
        
        return (()=>{
            
            removeEvents();
            clearTimeout(timer);
        })
    },[])
    
    const startTimer=()=>{
        
        if(timer){
            clearTimeout(timer)
        }
        timer=setTimeout(()=>{
            
            let lastInteractionTime=localStorage.getItem('lastInteractionTime')
            const diff = moment.duration(moment().diff(moment(lastInteractionTime)));
            let timeOutInterval=props.timeOutInterval?props.timeOutInterval:600000;
            if(isLogout){
                clearTimeout(timer)
            }else{
                if(diff.milliseconds<timeOutInterval){
                    startTimer();
                    props.onActive();
                }else{
                    handleLogout()
                }
            }
            
        },props.timeOutInterval?props.timeOutInterval:6000)
        
        
        
        
        
    }
    const addEvents=()=>{
        
        events.forEach(eventName=>{
            window.addEventListener(eventName,eventHandler)
        })
        
        startTimer();
    }
    
    const removeEvents=()=>{
        events.forEach(eventName=>{
            window.removeEventListener(eventName,eventHandler)
        })
    };
    const handleLogout = ()=>{
        removeEvents();
        clearTimeout(timer);
        setLogout(true)
        props.onLogout();        
    }
    
    return(
        <div>
        
        </div>
        )
        
    }
    
    export default IdleTimeOutHandler;