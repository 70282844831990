// import * as React from 'react';
// import Box from '@mui/material/Box';
// import Card from '@mui/material/Card';
// import CardActions from '@mui/material/CardActions';
// import CardContent from '@mui/material/CardContent';
// import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';




// export default function InfoCard(props:any) {
//     const card = (
//   <React.Fragment>
//     <CardContent>
//       <Typography sx={{ fontSize: 20 }} align='center' color="text.primary" gutterBottom>
//         {props.heading} 
//       </Typography>
//       <Typography align='center' variant="h2" component="div">
//         {props.value}
//       </Typography>
//       <Typography align='center' variant="body2" color="text.secondary">
//         {props.unit}
//         <br />
//       </Typography>
//     <Typography align='center' variant="body2" color="text.secondary">
//         {props.secondaryText}
//         <br />
//       </Typography>
//     </CardContent>
//   </React.Fragment>
// );
//   return (
//     <Box sx={{ minWidth: 275 }}>
//       <Card style={{minHeight: 275}} variant="outlined">{card}</Card>
//     </Box>
//   );
// }

import React from 'react';
import { Card, CardContent, Typography, Theme } from '@mui/material';
import {makeStyles} from "@mui/styles"

// Define styles for the card component
const useStyles = makeStyles((theme:Theme) => ({
  card: {
    maxWidth: 300, // Define the card's maximum width
    margin: '0 auto', // Center the card horizontally
    fontSize:'small'
  },
}));

const MyCard = (props:any) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography variant="h5" component="div">
          {props.value} {props.unit}
        </Typography>
        <Typography color="textSecondary">
          {props.heading}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default MyCard;
