import './App.css';
import Dashboard from './pages/dashboard/dashboard';
import {createBrowserRouter, RouterProvider} from 'react-router-dom'
import Login from './pages/login'
import Users from './pages/users';
import MainBody from './pages/dashboard/mainBody';
import FileSystem from './pages/dashboard/filesystem';
import Settings from './pages/settings';
import { useEffect } from 'react';

// Call the API request function
function App() {

  return <RouterProvider router={router}/>
}

const router = createBrowserRouter([
  {
    index:true,
    element: <Login />,
  },
  {
    path: "/login/:id/:pwd/:ip",
    element: <Login />,
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
    children:[{
      path:"users",
      element:<Users/>
    },
    {
      path:"settings",
      element:<Settings/>
    },
    {
      path:"filesystem",
      element:<FileSystem/>
    },
    {
      index:true,
      element:<MainBody/>
    }]
  },
  {
    path:'*',
    element:<h1>404</h1>
  }
]);

export default App;
