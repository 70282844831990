import { createTheme } from '@mui/material/styles';
import { colors } from './colors';

const theme = createTheme({
 palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
    background: {
      default: colors.background,
    },
    text: {
      primary: colors.textPrimary,
      secondary: colors.textSecondary,
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
    // Customize typography as needed
  },
  spacing: 8,
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none', // Preserve case in button text
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Custom elevation
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          // Customize the drawer's paper (background) styles
          backgroundColor: 'white', // Example: Change the background color
          color: colors.textPrimary, // Example: Change the text color
          width: '250px', // Example: Set the drawer width
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: colors.textPrimary, // Set the icon color to white
        },
      },
    },
  },
});

export default theme;
