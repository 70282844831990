import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import { connect } from 'react-redux'
import { Box, Button, Card, FormControl, FormLabel, IconButton, InputAdornment, InputLabel, OutlinedInput, Paper, Radio, RadioGroup, Tooltip } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { ISettings } from '../../types/settings';
import { setConfigRequest } from '../../store/data/actions';
import { isMotionValue } from 'framer-motion';


const defaultSettings= JSON.parse('{"DeviceConfig":{"deviceId":"THFR1","password":"Password","location":"","pia":2,"debug":0,"output":0,"isBufferActive":0,"totalSpace":3121152,"usedSpace":65536},"WiFiConfig":{"ssid":"MeeruHome","wifipass":"whatthefluff"},"MqttConfig":{"clientId":"","server":"","port":0,"username":"","password":"","QoS":0},"HttpConfig":{"URI":"http://192.168.4.1","port":80}}')

export const Settings = (props: any) => {
    const [hide, setHide] = React.useState(true)
    const [thide, setTHide] = React.useState(true)
    const [mhide, setMHide] = React.useState(true)
    const [settings,setSettings]=React.useState<ISettings|undefined>(defaultSettings)
    const [defaultBufferStatus,setDefaultBufferStatus]:any=React.useState()
    React.useEffect(()=>{
        if(Object.keys(props.settings).length !== 0){
            setSettings(props.settings)
        }
    },[props.settings])
    React.useEffect(()=>{
        if(settings&&settings.DeviceConfig&&settings.DeviceConfig.isBufferActive){
            setDefaultBufferStatus(settings.DeviceConfig.isBufferActive)
        }
    },[settings?.DeviceConfig,props.settings])
    const handleOnSubmit=(e:React.FormEvent)=>{
        e.preventDefault()
        props.saveSettings({
            authKey:props.authKey.trim(),
            config:settings,
            path:props.path
        })
    }

    return (
        <div>
        <Box component="form" onSubmit={(e)=>handleOnSubmit(e)}>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <Paper style={{ padding: 30 }}>
                        <Typography variant='h5' align='center' color="text.primary" gutterBottom>
                            {"Device Settings"}
                        </Typography>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="deviceId"
                                label="Device ID"
                                name="deviceId"
                                autoComplete="deviceId"
                                value={settings?.DeviceConfig.deviceId}
                                autoFocus
                            />
                            <FormControl sx={{ mt: 1 }} fullWidth variant="outlined">
                                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                <OutlinedInput
                                    required
                                    id="password"
                                    name='password'
                                    label="Location"
                                    type={hide ? 'password' : 'text'}
                                    value={settings?.DeviceConfig.password}
                                    onChange={(e)=>{
                                        setSettings((prev:any)=>{
                                            return{
                                                ...prev,
                                                DeviceConfig:{
                                                    ...prev.DeviceConfig,
                                                    password:e.target.value
                                                }
                                            }
                                        })
                                    }}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <Tooltip title={hide?'Show Password':'Hide Password'}>
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => {
                                                        setHide(!hide)
                                                    }}
                                                    // onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {hide ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </Tooltip>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                            <TextField
                                margin="normal"
                                fullWidth
                                id="location"
                                label="Location"
                                name="location"
                                autoComplete="location"
                                value={settings?.DeviceConfig.location}
                                onChange={(e)=>{
                                    setSettings((prev:any)=>{
                                        return{
                                            ...prev,
                                            DeviceConfig:{
                                                ...prev.DeviceConfig,
                                                location:e.target.value
                                            }
                                        }
                                    })
                                }}
                            />
                            <FormControl sx={{ mt: 2 }}>
                                <FormLabel>Protocol In Action</FormLabel>
                                <RadioGroup
                                    row
                                    value={settings?.DeviceConfig.pia}
                                    name="controlled-radio-buttons-group"
                                    onChange={(e)=>{
                                        if(e.target.value==='2'){
                                        setSettings((prev:any)=>{
                                            return{
                                                ...prev,
                                                DeviceConfig:{
                                                    ...prev?.DeviceConfig,
                                                    isBufferActive:1
                                                }
                                            }
                                        })
                                    }
                                    else
                                    {
                                        setSettings((prev:any)=>{
                                            return{
                                                ...prev,
                                                DeviceConfig:{
                                                    ...prev?.DeviceConfig,
                                                    isBufferActive:defaultBufferStatus
                                                }
                                            }
                                        })
                                    }
                                        setSettings((prev:any)=>{
                                            return{
                                                ...prev,
                                                DeviceConfig:{
                                                    ...prev?.DeviceConfig,
                                                    pia:e.target.value
                                                }
                                            }
                                        })
                                    }}
                                >
                                    <FormControlLabel value={0} control={<Radio />} label="HTTP" />
                                    <FormControlLabel value={1} control={<Radio />} label="MQTT" />
                                    <FormControlLabel value={2} control={<Radio />} label="Offline Attendance" />
                                    <FormControlLabel value={3} control={<Radio />} label="Offline Access Management" />
                                </RadioGroup>
                            </FormControl>
                            <br/>
                            <FormControl sx={{ mt: 2 }}>
                                <FormLabel>Debug</FormLabel>
                                <RadioGroup
                                    row
                                    name="controlled-radio-buttons-group"
                                    value={settings?.DeviceConfig.debug}
                                    onChange={(e)=>{
                                        setSettings((prev:any)=>{
                                            return{
                                                ...prev,
                                                DeviceConfig:{
                                                    ...prev?.DeviceConfig,
                                                    debug:e.target.value
                                                }
                                            }
                                        })
                                    }}                                >
                                    <FormControlLabel value={1} control={<Radio />} label="Yes" />
                                    <FormControlLabel value={0} control={<Radio />} label="No" />
                                </RadioGroup>
                            </FormControl>
                            <br/>
                            <FormControl sx={{ mt: 2 }}>
                                <FormLabel>Outputs</FormLabel>
                                <RadioGroup
                                    row
                                    name="controlled-radio-buttons-group"
                                    value={settings?.DeviceConfig.output}
                                    onChange={(e)=>{
                                        setSettings((prev:any)=>{
                                            return{
                                                ...prev,
                                                DeviceConfig:{
                                                    ...prev?.DeviceConfig,
                                                    output:e.target.value
                                                }
                                            }
                                        })
                                    }}>
                                    <FormControlLabel value={0} control={<Radio />} label="Default" />
                                    <FormControlLabel value={1} control={<Radio />} label="Weigend" />
                                    <FormControlLabel value={2} control={<Radio />} label="Disabled" />
                                </RadioGroup>
                            </FormControl>
                            <br/>
                            <FormControl sx={{ mt: 2 }}>
                                <FormLabel>Buffer Status</FormLabel>
                                <RadioGroup
                                    row
                                    value={settings?.DeviceConfig.isBufferActive}
                                    name="controlled-radio-buttons-group"
                                    onChange={(e)=>{
                                        setSettings((prev:any)=>{
                                            return{
                                                ...prev,
                                                DeviceConfig:{
                                                    ...prev?.DeviceConfig,
                                                    isBufferActive:e.target.value
                                                }
                                            }
                                        })
                                    }}
                                >
                                    <FormControlLabel value={0} control={<Radio />} label="Disabled" disabled={settings?.DeviceConfig.pia==2?true:false}/>
                                    <FormControlLabel value={1} control={<Radio />} label="Active" />
                                </RadioGroup>
                            </FormControl>
                    </Paper>
                    <br/>
                    <Paper style={{ padding: 30 }}>
                        <Typography variant='h5' align='center' color="text.primary" gutterBottom>
                            {"HTTP Settings"}
                        </Typography>
                            <TextField
                                margin="normal"
                                fullWidth
                                id="httpserver"
                                label="Server Address"
                                name="httpserver"
                                autoComplete="httpserver"
                                value={settings?.HttpConfig.URI}
                                onChange={(e)=>{
                                    setSettings((prev:any)=>{
                                        return{
                                            ...prev,
                                            HttpConfig:{
                                                ...prev.HttpConfig,
                                                URI:e.target.value
                                            }
                                        }
                                    })
                                }}
                            />
                            <TextField
                                margin="normal"
                                fullWidth
                                id="port"
                                label="Port"
                                name="port"
                                autoComplete="port"
                                value={settings?.HttpConfig.port}
                                onChange={(e)=>{
                                    setSettings((prev:any)=>{
                                        return{
                                            ...prev,
                                            HttpConfig:{
                                                ...prev.HttpConfig,
                                                port:e.target.value
                                            }
                                        }
                                    })
                                }}
                            />
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Paper style={{ padding: 30 }}>
                        <Typography variant='h5' align='center' color="text.primary" gutterBottom>
                            {"WiFi Settings"}
                        </Typography>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="ssid"
                                label="WiFi SSID"
                                name="ssid"
                                autoComplete="ssid"
                                value={settings?.WiFiConfig.ssid}
                                onChange={(e)=>{
                                    setSettings((prev:any)=>{
                                        return{
                                            ...prev,
                                            WiFiConfig:{
                                                ...prev.WiFiConfig,
                                                ssid:e.target.value
                                            }
                                        }
                                    })
                                }}
                            />
                            <FormControl sx={{ mt: 1 }} fullWidth variant="outlined">
                                <InputLabel htmlFor="outlined-adornment-password">WiFi Password</InputLabel>
                                <OutlinedInput
                                    id="wifipassword"
                                    name='wifipassword'
                                    label="WiFi Password"
                                    type={thide ? 'password' : 'text'}
                                    value={settings?.WiFiConfig.wifipass}
                                    onChange={(e)=>{
                                        setSettings((prev:any)=>{
                                            return{
                                                ...prev,
                                                WiFiConfig:{
                                                    ...prev.WiFiConfig,
                                                    wifipass:e.target.value
                                                }
                                            }
                                        })
                                    }}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <Tooltip title='Get last scanned tag ID'>
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => {
                                                        setTHide(!thide)
                                                    }}
                                                    // onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {thide ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </Tooltip>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                    </Paper>
                    <br/>
                    <Paper style={{ padding: 30 }}>
                        <Typography variant='h5' align='center' color="text.primary" gutterBottom>
                            {"MQTT Settings"}
                        </Typography>
                            <TextField
                                margin="normal"
                                fullWidth
                                id="clientId"
                                label="Client ID"
                                name="clientId"
                                autoComplete="clientId"
                                value={settings?.MqttConfig.clientId}
                                onChange={(e)=>{
                                    setSettings((prev:any)=>{
                                        return{
                                            ...prev,
                                            MqttConfig:{
                                                ...prev.MqttConfig,
                                                clientId:e.target.value
                                            }
                                        }
                                    })
                                }}
                            />
                            <TextField
                                margin="normal"
                                fullWidth
                                id="mqttserver"
                                label="Server Address"
                                name="serveraddr"
                                autoComplete="serveraddr"
                                value={settings?.MqttConfig.server}
                                onChange={(e)=>{
                                    setSettings((prev:any)=>{
                                        return{
                                            ...prev,
                                            MqttConfig:{
                                                ...prev.MqttConfig,
                                                server:e.target.value
                                            }
                                        }
                                    })
                                }}
                            />
                            <TextField
                                margin="normal"
                                fullWidth
                                id="mqttport"
                                label="Port"
                                name="port"
                                autoComplete="port"
                                value={settings?.MqttConfig.port}
                                onChange={(e)=>{
                                    setSettings((prev:any)=>{
                                        return{
                                            ...prev,
                                            MqttConfig:{
                                                ...prev.MqttConfig,
                                                port:e.target.value
                                            }
                                        }
                                    })
                                }}
                            />       
                            <TextField
                                margin="normal"
                                fullWidth
                                id="mqttusername"
                                label="Username"
                                name="username"
                                autoComplete="username"
                                value={settings?.MqttConfig.username}
                                onChange={(e)=>{
                                    setSettings((prev:any)=>{
                                        return{
                                            ...prev,
                                            MqttConfig:{
                                                ...prev.MqttConfig,
                                                username:e.target.value
                                            }
                                        }
                                    })
                                }}
                            />           
                            <FormControl sx={{ mt: 1 }} fullWidth variant="outlined">
                                <InputLabel htmlFor="outlined-adornment-password">Mqtt Password</InputLabel>
                                <OutlinedInput
                                    id="mqttpassword"
                                    name='mqttpassword'
                                    label="Mqtt Password"
                                    type={mhide ? 'password' : 'text'}
                                    value={settings?.MqttConfig.password}
                                    onChange={(e)=>{
                                        setSettings((prev:any)=>{
                                            return{
                                                ...prev,
                                                MqttConfig:{
                                                    ...prev.MqttConfig,
                                                    password:e.target.value
                                                }
                                            }
                                        })
                                    }}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <Tooltip title='Get last scanned tag ID'>
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => {
                                                        setMHide(!mhide)
                                                    }}
                                                    // onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {mhide ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </Tooltip>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                    </Paper>
                </Grid>
            <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, m: 3 }}
            >
                Save
            </Button>
            </Grid>
        </Box>
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    settings:state.data.config,
    authKey:localStorage.getItem('authKey'),
    path:localStorage.getItem('path'),
})

const mapDispatchToProps = {
    saveSettings:setConfigRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings)