import {
    BUFFER_DATA_REQUEST,
    BUFFER_DATA_SUCCESS,
    BUFFER_DATA_FAILURE,
    BUFFER_UPDATE_DATA,
    BUFFER_UPDATE_DATA_SUCCESS,
    BUFFER_UPDATE_DATA_FAILURE,
    GET_CONFIG,
    GET_CONFIG_SUCCESS,
    GET_CONFIG_FAILURE,
    GET_USERS,
    GET_USERS_SUCCESS,
    GET_USERS_FAILURE,
    SAVE_USERS_SUCCESS,
    GET_FILES,
    GET_FILES_SUCCESS,
    GET_FILES_FAILURE
  } from "./actionTypes";
  
  import { AuthActions, DataState, User } from "./types";
  
  const initialState: DataState = {
    pending: false,
    data: null,
    error: null,
    config:{},
    userData:[],
    files:undefined
  };
  
  const reducers = (state = initialState, action: AuthActions) => {
    switch (action.type) {  
      case BUFFER_DATA_REQUEST:
        return {
          ...state,
          pending: true,
        };
      case BUFFER_DATA_SUCCESS:
        return {
          ...state,
          pending: false,
          data: action.payload.data,
          error: null,
        };
      case BUFFER_DATA_FAILURE:
        return {
          ...state,
          pending: false,
          data: null,
          error: action.payload.error,
        };
      case BUFFER_UPDATE_DATA: 
        return{
          ...state,
          pending: true
        };
      case BUFFER_UPDATE_DATA_SUCCESS:
        return{
          ...state,
          pending: false,
          data:action.payload.data
        }
      case BUFFER_UPDATE_DATA_FAILURE:
        return{
          ...state,
          pending: false,
        }
      case GET_CONFIG: 
        return{
          ...state,
          pending: true
        };
      case GET_CONFIG_SUCCESS:
        return{
          ...state,
          pending: false,
          config: action.payload.config
        }
      case GET_CONFIG_FAILURE:
        return{
          ...state,
          pending: false,
          error:action.payload.error
        }
      case GET_USERS: 
        return{
          ...state,
          pending: true
        };
      case GET_USERS_SUCCESS:
        const receivedUsers=action.payload.userData
        const users=mapUserArrayToUserRow(receivedUsers)
        return{
          ...state,
          pending: false,
          userData: users
        }
      case GET_USERS_FAILURE:
        return{
          ...state,
          pending: false,
          error:action.payload.error,
          userData:[]
        }
      case GET_FILES: 
        return{
          ...state,
          pending: true
        };
      case GET_FILES_SUCCESS:
        return{
          ...state,
          pending: false,
          files: action.payload.files
        }
      case GET_FILES_FAILURE:
        return{
          ...state,
          pending: false,
          error:action.payload.error,
          userData:[]
        }
      case SAVE_USERS_SUCCESS:
        const updatedUsers:any=action.payload.userData
        const updatedUsersRow=mapUserArrayToUserRow(updatedUsers)
        return{
          ...state,
          pending: false,
          userData:updatedUsersRow
        }
      default:
        return {
          ...state,
        };
    }
  };
  
  const mapUserArrayToUserRow = (receivedUsers:any[])=>{
    const users:User[]=[]
    receivedUsers.forEach((element:any,index:number) => {
      const user:User={
        id:index+1,
        name:element[0],
        tagId:element[2],
        userId:element[1],
        contactInfo:element[3],
        inTime:element[4],
        outTime:element[5]
      }
      users.push(user)
    });
    return users
  }

  export default reducers;