export const BUFFER_DATA_REQUEST = "BUFFER_DATA_REQUEST";
export const BUFFER_DATA_SUCCESS = "BUFFER_DATA_SUCCESS";
export const BUFFER_DATA_FAILURE = "BUFFER_DATA_FAILURE";
export const BUFFER_UPDATE_DATA = "BUFFER_UPDATE_DATA";
export const BUFFER_UPDATE_DATA_SUCCESS = "BUFFER_UPDATE_DATA_SUCCESS";
export const BUFFER_UPDATE_DATA_FAILURE = "BUFFER_UPDATE_DATA_FAILURE";
export const GET_CONFIG = "GET_CONFIG";
export const GET_CONFIG_SUCCESS = "GET_CONFIG_SUCCESS";
export const GET_CONFIG_FAILURE = "GET_CONFIG_FAILURE";
export const SET_CONFIG = "SET_CONFIG";
export const SET_CONFIG_SUCCESS = "SET_CONFIG_SUCCESS";
export const SET_CONFIG_FAILURE = "SET_CONFIG_FAILURE";
export const GET_USERS = "GET_USERS";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAILURE = "GET_USERS_FAILURE";
export const SAVE_USERS = "SAVE_USERS";
export const SAVE_USERS_SUCCESS = "SAVE_USERS_SUCCESS";
export const SAVE_USERS_FAILURE = "SAVE_USERS_FAILURE";
export const GET_FILES = "GET_FILES";
export const GET_FILES_SUCCESS = "GET_FILES_SUCCESS";
export const GET_FILES_FAILURE = "GET_FILES_FAILURE";
export const GET_FILE_CONTENT = "GET_FILE_CONTENT";
export const GET_FILE_CONTENT_SUCCESS = "GET_FILE_CONTENT_SUCCESS";
export const GET_FILE_CONTENT_FAILURE = "GET_FILE_CONTENT_FAILURE";