import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
  Grid,
  Paper,
  TextareaAutosize,
  Box,
  Theme,
  Fab,
  Menu,
  MenuItem,
} from "@mui/material";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CodeIcon from "@mui/icons-material/Code";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { getFilesRequest } from "../../store/data/actions";
import { connect } from "react-redux";

const useStyles = makeStyles((theme: Theme) => ({
  listItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(1, 2),
    background: "#f5f5f5", // Grey background color for internal components
    height: "100%",
  },
  listItemIcon: {
    fontSize: 36, // Adjust the icon size as needed
    marginBottom: theme.spacing(1),
  },
  contentPaper: {
    padding: theme.spacing(2),
    height: "100%",
  },
  textarea: {
    width: "100%",
    background: "#f0f0f0", // Grey background color for TextareaAutosize,
    overflowY: "auto",
    resize: "vertical",
    padding: "15px",
    borderColor: "black",
  },
  root: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 1000, // Ensure the button is above other content
  },
}));

interface FileDetailsProps {
  filename: string;
  filesize: number;
  creationTime: number;
  lastModified: number;
}

function timestampToDatetime(timestamp: number): string {
  const date = new Date(timestamp * 1000); // Convert seconds to milliseconds
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };
  return date.toLocaleDateString("en-US", options);
}

const FileDetails: React.FC<FileDetailsProps> = ({
  filename,
  filesize,
  creationTime,
  lastModified,
}) => (
  <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
    <div style={{ marginRight: "16px" }}>
      <strong>Name:</strong>
      <br /> {filename}
    </div>
    <div style={{ marginRight: "16px" }}>
      <strong>Size:</strong>
      <br /> {filesize} KB
    </div>
    <div style={{ marginRight: "16px" }}>
      <strong>Created on:</strong>
      <br /> {timestampToDatetime(creationTime)} seconds ago
    </div>
    <div>
      <strong>Last Modified:</strong>
      <br /> {timestampToDatetime(lastModified)} seconds ago
    </div>
  </div>
);

const FileViewer: React.FC = (props: any) => {
  const classes = useStyles();
  const [selectedFile, setSelectedFile] = useState<FileDetailsProps | null>(
    null
  );
  const [files, setFiles] = useState<Array<FileDetailsProps>>([]);
  const [content, setContent] = useState<string>("");
  const [showDeleteBtn, setShowDeleteButton] = useState<boolean>(false);
  const [contextMenuPosition, setContextMenuPosition] = useState<{
    top: number;
    left: number;
  } | null>(null);
  const [contextMenuFile, setContextMenuFile] =
    useState<FileDetailsProps | null>(null);

  useEffect(() => {
    props.getFiles({
      authKey: props.authKey,
    });
  }, []);

  useEffect(() => {
    props.files && setFiles(props.files);
  }, [props.files]);

  function prettifyJSON(
    jsonString: string,
    indentation: number | string = 2
  ): string {
    try {
      const parsedJSON = JSON.parse(jsonString);
      return JSON.stringify(parsedJSON, null, indentation);
    } catch (error) {
      console.error("Invalid JSON:", error);
      return jsonString; // Return the original string if parsing fails
    }
  }
  function prettifyCSV(csv: string, delimiter: string = ","): string {
    const rows = csv.split("\n");
    if (rows.length < 2) {
      // Not enough lines to prettify
      return csv;
    }
    const headers = rows[0].split(delimiter);
    const formattedCSV: string[] = [headers.join(delimiter)];

    for (let i = 1; i < rows.length; i++) {
      const columns = rows[i].split(delimiter);
      if (columns.length === headers.length) {
        formattedCSV.push(columns.join(delimiter));
      } else {
        // Number of columns doesn't match headers, so just join without headers
        formattedCSV.push(rows[i]);
      }
    }

    return formattedCSV.join("\n");
  }
  function prettifyHTML(html: string): string {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const serializer = new XMLSerializer();

    return serializer.serializeToString(doc.documentElement);
  }
  const handleContextMenu = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    file: FileDetailsProps
  ) => {
    event.preventDefault(); // Prevent the default browser context menu
    // Implement your right-click action here
    setContextMenuPosition({ top: event.clientY, left: event.clientX });
    setContextMenuFile(file);
  };
  const deleteFile = (file: FileDetailsProps) => {
    fetch(localStorage.getItem("path") + "/deleteFile", {
      method: "POST",
      body: JSON.stringify({
        authKey: localStorage.getItem("authKey"),
        filename: file.filename,
      }),
    })
      .then((response) => {
        window.location.reload();
      })
      .catch((e) => {
        console.error(e);
      });
  };
  const handleCloseContextMenu = () => {
    setContextMenuPosition(null);
    setContextMenuFile(null);
  };

  const handleContextMenuAction = (action: string) => {
    // Implement your context menu action here
    console.info(`Selected action: ${action}`);
    switch (action) {
      case "View":
        contextMenuFile && handleFileClick(contextMenuFile);
        break;
      case "Delete":
        contextMenuFile && deleteFile(contextMenuFile);
        break;
    }
    handleCloseContextMenu();
  };

  useEffect(() => {
    if (!selectedFile) return;
    fetch(localStorage.getItem("path") + "/getFileContent", {
      method: "POST",
      body: JSON.stringify({
        authKey: localStorage.getItem("authKey"),
        filename: selectedFile?.filename,
      }),
    })
      .then((response) => {
        return response.text();
      })
      .then((responseString) => {
        if (selectedFile.filename.endsWith(".json")) {
          setContent(prettifyJSON(responseString));
          setShowDeleteButton(true);
          return;
        } else if (selectedFile.filename.endsWith(".html")) {
          setContent(prettifyHTML(responseString));
          setShowDeleteButton(false);
          return;
        }
        setContent(responseString);
      });
  }, [selectedFile]);

  const handleFileClick = (file: FileDetailsProps) => {
    setSelectedFile(file);
  };

  const handleClose = () => {
    setSelectedFile(null);
  };

  const createListItem = (
    file1: FileDetailsProps,
    file2: FileDetailsProps | null,
    index: number
  ) => (
    <Box key={index} display="flex" justifyContent="space-between">
      <div>
        <ListItem
          button
          onClick={() => handleFileClick(file1)}
          className={classes.listItem}
          onContextMenu={(event: any) => handleContextMenu(event, file1)}
        >
          {file1.filename.endsWith(".html") && (
            <CodeIcon className={classes.listItemIcon} />
          )}
          {file1.filename.split(".").pop() === "csv" && (
            <InsertDriveFileIcon className={classes.listItemIcon} />
          )}
          {file1.filename.split(".").pop() === "json" && (
            <InsertDriveFileIcon className={classes.listItemIcon} />
          )}
          <ListItemText primary={file1.filename} />
        </ListItem>
      </div>
      {file2 && (
        <div>
          <ListItem
            button
            onClick={() => handleFileClick(file2)}
            className={classes.listItem}
            onContextMenu={(event: any) => handleContextMenu(event, file2)}
          >
            {file2.filename.endsWith(".html") && (
              <CodeIcon className={classes.listItemIcon} />
            )}
            {file2.filename.split(".").pop() === "csv" && (
              <PictureAsPdfIcon className={classes.listItemIcon} />
            )}
            {file2.filename.split(".").pop() === "json" && (
              <InsertDriveFileIcon className={classes.listItemIcon} />
            )}
            {file2.filename.split(".").pop() === "csv" && (
              <InsertDriveFileIcon className={classes.listItemIcon} />
            )}
            <ListItemText primary={file2.filename} />
          </ListItem>
        </div>
      )}
    </Box>
  );
  const itemsInRows: JSX.Element[] = [];
  for (let i = 0; i < files.length; i += 2) {
    const file1 = files[i];
    const file2 = i + 1 < files.length ? files[i + 1] : null;
    itemsInRows.push(createListItem(file1, file2, i));
  }
  return (
    <Container maxWidth="xl">
      <Menu
        open={!!contextMenuPosition}
        onClose={handleCloseContextMenu}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenuPosition
            ? { top: contextMenuPosition.top, left: contextMenuPosition.left }
            : undefined
        }
      >
        <MenuItem onClick={() => handleContextMenuAction("View")}>
          View
        </MenuItem>
        <MenuItem onClick={() => handleContextMenuAction("Delete")}>
          Delete
        </MenuItem>
      </Menu>
      {itemsInRows.length > 0 && (
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Paper elevation={3} style={{ padding: "16px", height: "100%" }}>
              <List>{itemsInRows}</List>
            </Paper>
          </Grid>
          <Grid item xs={8}>
            {selectedFile ? (
              <Paper elevation={3} className={classes.contentPaper}>
                <FileDetails
                  creationTime={selectedFile.creationTime}
                  filename={selectedFile.filename}
                  lastModified={selectedFile.lastModified}
                  filesize={selectedFile.filesize}
                />
                <br />

                <TextareaAutosize
                  value={content}
                  className={classes.textarea}
                  minRows={3}
                />
                <Button
                  variant="contained"
                  onClick={handleClose}
                  color="primary"
                  sx={{ marginTop: 2 }}
                >
                  Close
                </Button>
              </Paper>
            ) : null}
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

const mapStateToProps = (state: any) => ({
  authKey: localStorage.getItem("authKey"),
  files: state.data.files,
});

const mapDispatchToProps = {
  getFiles: getFilesRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(FileViewer);
